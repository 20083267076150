<template>
   <div class="basicProcessing">
      <div ref="search" class="search">
         <el-card class="box-card" shadow="never" :body-style="{padding:'10px'}">
            <el-form :inline="true" :model="searchForm" label-width="80px">
               <search-open-btn :searchShow="searchShow" @searchOpen="searchOpen"></search-open-btn>
               <el-form-item label="参数名称">
                  <el-input v-model="searchForm.paramMiName" :size="$store.state.size" placeholder="参数名称" clearable @keyup.enter.native="searchSubmit(true)"></el-input>
               </el-form-item>
               <el-form-item label="参数类型">
                  <el-select v-model="searchForm.paramMiType" :size="$store.state.size" placeholder="参数类型" clearable>
                     <el-option label="文本框" value="0"></el-option>
                     <el-option label="下拉值" value="1"></el-option>
                  </el-select>
               </el-form-item>
               <el-form-item label="制造商">
                  <el-select v-model="searchForm.paramMiCustomerId" :size="$store.state.size" placeholder="制造商" clearable>
                     <el-option v-for='item in manufacturerData' :key='item.sysCiId' :value='item.sysCiId' :label="item.sysCiShortName" @click.native="changeCustomerType(item.sysCiId)"></el-option>
                  </el-select>
               </el-form-item>
               <el-form-item label="品牌">
                  <el-select v-model="searchForm.paramMiBrandId" :size="$store.state.size" placeholder="品牌" clearable>
                     <el-option v-for='item in brandList' :key='item.goodsBiId' :value='item.goodsBiId' :label="item.goodsBiName"></el-option>
                  </el-select>
               </el-form-item>
               <el-form-item label="排序ID" v-show="searchShow">
                  <el-input v-model="searchForm.paramMiSortId" :size="$store.state.size" placeholder="排序ID" clearable @keyup.enter.native="searchSubmit(true)"></el-input>
               </el-form-item>
               <el-form-item label="启用状态" v-show="searchShow">
                  <el-select v-model="searchForm.paramMiStatus" :size="$store.state.size" placeholder="启用状态" clearable>
                     <el-option label="启用" value="0"></el-option>
                     <el-option label="停用" value="1"></el-option>
                  </el-select>
               </el-form-item>
               <el-form-item label="上级参数" v-show="searchShow">
                  <el-select v-model="searchForm.paramMiParentId" :size="$store.state.size" placeholder="上级参数" clearable>
                     <el-option v-for='item in paramParentData' :key='item.paramMiId' :value='item.paramMiId' :label="item.paramMiName"></el-option>
                  </el-select>
               </el-form-item>
            </el-form>
            <el-divider></el-divider>
            <el-button v-if="hasPermission('5-1-a')" type="primary" :size="$store.state.size" @click="searchSubmit" icon="el-icon-search">查询
            </el-button>
            <el-button v-if="hasPermission('5-1-b')" type="default" plain :size="$store.state.size" @click="resetSubmit" icon="el-icon-delete">清空
            </el-button>
            <el-button v-if="hasPermission('5-1-c')" type="primary" :size="$store.state.size" @click="saveOpen" icon="el-icon-circle-plus-outline">新增
            </el-button>
         </el-card>
      </div>
      <el-table :data="tableData" :height="tableHeight" v-loading="tableLoading" border @sort-change="sortChange" :size="this.$common.tableLineHeight()" :header-cell-style="{background:'#f5f7fa',color:'#515a6e', fontWeight:'900'}" style="width: 100%; margin-top: 10px">
         <el-table-column prop="paramMiName" align="center" label="参数名称">
         </el-table-column>
         <el-table-column prop="paramType" align="center" label="参数类型">
         </el-table-column>
         <el-table-column prop="customer" align="center" label="所属制造商">
         </el-table-column>
         <el-table-column prop="paramMiSortId" align="center" label="排序id">
         </el-table-column>
         <el-table-column prop="parentName" align="center" label="上级参数">
         </el-table-column>
         <el-table-column align="center" label="启用状态" width="150">
            <template slot-scope="scope">
               <el-switch v-model="scope.row.paramMiStatus" :active-value="0" :inactive-value="1" active-text="启用" inactive-text="停用" @change="saveMiStatus(scope.row)">
               </el-switch>
            </template>
         </el-table-column>
         <el-table-column align="center" label="操作" width="260">
            <template slot-scope="scope">
               <el-button v-if="hasPermission('5-1-g') && scope.row.paramType === '下拉框'" type="text" size="small" @click="setOptionOpen(scope.row.paramMiId)" icon="el-icon-setting">下拉维护
               </el-button>
               <!-- <el-button v-if="hasPermission('5-1-d')" type="text" size="small" @click="seeOpen(scope.row)"
                     icon="el-icon-document">查看
          </el-button> -->
               <el-button v-if="hasPermission('5-1-e')" type="text" size="small" @click="editOpen(scope.row)" icon="el-icon-edit-outline">编辑
               </el-button>
               <el-button v-if="hasPermission('5-1-f')" type="text" size="small" @click="delOpen(scope.row.paramMiId)" icon="el-icon-delete">删除
               </el-button>
            </template>
         </el-table-column>
      </el-table>
      <pagination :page-size='currentPageSize' :current-page="currentPage" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange">
      </pagination>

      <!--    保存模态框-->
      <assembly-dialog :title="dialogTitle" :visible.sync="dialogVisibleSave" width="30%" @before-close="handleClose">
         <el-form :model="saveForm" :rules="saveRules" ref="saveForm" label-width="80px">
            <el-form-item label="参数名称" prop="paramMiName">
               <el-input v-model="saveForm.paramMiName" :size="$store.state.size" placeholder="参数名称" clearable></el-input>
            </el-form-item>

            <el-form-item label="参数类型" prop="paramMiType">
               <el-radio v-model="saveForm.paramMiType" :label="0">文本框</el-radio>
               <el-radio v-model="saveForm.paramMiType" :label="1">下拉值</el-radio>
            </el-form-item>
            <el-form-item label="制造商" prop="customer">
               <el-select v-model="saveForm.paramMiCustomerId" :size="$store.state.size" placeholder="制造商" clearable>
                  <el-option v-for='item in manufacturerData' :key='item.sysCiId' :value='item.sysCiId' :label="item.sysCiShortName" @click.native="changeCustomerType(item.sysCiId)"></el-option>
               </el-select>
            </el-form-item>
            <el-form-item label="品牌" prop="brand">
               <el-select v-model="saveForm.paramMiBrandId" :size="$store.state.size" placeholder="品牌" clearable>
                  <el-option v-for='item in brandList' :key='item.goodsBiId' :value='item.goodsBiId' :label="item.goodsBiName"></el-option>
               </el-select>
            </el-form-item>

            <el-form-item label="排序ID" prop="paramMiSortId">
               <el-input v-model="saveForm.paramMiSortId" :size="$store.state.size" placeholder="排序ID" clearable></el-input>
            </el-form-item>

            <el-form-item label="启用状态" prop="paramMiStatus">
               <el-select v-model="saveForm.paramMiStatus" :size="$store.state.size" placeholder="启用状态" clearable>
                  <el-option label="启用" :value="0"></el-option>
                  <el-option label="停用" :value="1"></el-option>
               </el-select>
            </el-form-item>
            <el-form-item label="上级参数" prop="paramMiParentId">
               <el-select v-model="saveForm.paramMiParentId" :size="$store.state.size" placeholder="上级参数" clearable @change="parentParamChange">
                  <el-option v-for='item in paramParentData' :key='item.paramMiId' :value='item.paramMiId' :label="item.paramMiName"></el-option>
               </el-select>
            </el-form-item>
         </el-form>
         <span slot="footer" class="dialog-footer">
            <el-button :size="$store.state.size" @click="clearForm('saveForm')">清 空</el-button>
            <el-button :size="$store.state.size" type="primary" @click="saveSubmit('saveForm')">确 定</el-button>
         </span>
      </assembly-dialog>
      <!--    查看模态框-->
      <assembly-dialog title="查看" :visible.sync="dialogVisibleSee" width="30%" @before-close="seeClose">
         <el-descriptions class="margin-top" :column="1" :size="$store.state.size" border>
            <el-descriptions-item>
               <template slot="label">
                  参数名称
               </template>
               {{ seeForm.paramMiName }}
            </el-descriptions-item>
            <el-descriptions-item>
               <template slot="label">
                  参数类型
               </template>
               {{ seeForm.paramType }}
            </el-descriptions-item>
            <el-descriptions-item>
               <template slot="label">
                  制造商
               </template>
               {{ seeForm.customer }}
            </el-descriptions-item>
            <el-descriptions-item>
               <template slot="label">
                  品牌
               </template>
               {{ seeForm.brand }}
            </el-descriptions-item>
            <el-descriptions-item>
               <template slot="label">
                  排序id
               </template>
               {{ seeForm.paramMiSortId }}
            </el-descriptions-item>
            <el-descriptions-item>
               <template slot="label">
                  启用状态
               </template>
               {{ seeForm.paramMiStatus === 0 ? '启用' : '停用' }}
            </el-descriptions-item>
            <el-descriptions-item>
               <template slot="label">
                  上级参数
               </template>
               {{ seeForm.parentName }}
            </el-descriptions-item>
         </el-descriptions>
         <span slot="footer" class="dialog-footer">
            <el-button :size="$store.state.size" @click="seeClose">关 闭</el-button>
         </span>
      </assembly-dialog>
      <!--    维护下拉值模态框-->
      <assembly-dialog title="下拉项维护" :visible.sync="dialogVisibleSetOption" width="30%" @before-close="setOptionClose">
         <el-form :model="saveForm" :rules="saveRules" ref="saveForm" label-width="80px">
            <el-form-item>
               <el-tag style="margin: 5px;cursor: pointer" :key="tag" v-for="tag in dynamicTags" :disable-transitions="false" @close="handleClose1(tag)" @click="showInput(tag)">
                  {{ tag }}<i style="margin-left: 10px; cursor: pointer" class="el-icon-edit"></i>
               </el-tag>
               <el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" size="small" @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm">
               </el-input>
               <el-button v-else class="button-new-tag" size="small" @click="showInput" icon="el-icon-circle-plus-outline">新增名称
               </el-button>
            </el-form-item>

            <el-form-item>
               <el-tag style="margin: 5px;cursor: pointer" :key="tag" v-for="tag in dynamicTags" :disable-transitions="false" @close="handleClose1(tag)" @click="showInput(tag)">
                  {{ tag }}<i style="margin-left: 10px; cursor: pointer" class="el-icon-edit"></i>
               </el-tag>
               <el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" size="small" @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm">
               </el-input>
               <el-button v-else class="button-new-tag" size="small" @click="showInput" icon="el-icon-circle-plus-outline">新增
               </el-button>
            </el-form-item>
         </el-form>
         <span slot="footer" class="dialog-footer">
            <el-button :size="$store.state.size" @click="setOptionClose">关 闭</el-button>
            <el-button :size="$store.state.size" @click="saveMachiningDroplist">确 定</el-button>
         </span>
      </assembly-dialog>
      <!--    下拉项维护模态框-->
      <div v-if="dialogVisibleRetailer">
         <assembly-dialog :visible.sync="dialogVisibleRetailer" :show-close="false" :fullscreen="fullscreen" lock-scroll width="60%" @before-close="dialogVisibleRetailer = false;">
            <template slot="title" class="el-dialog__header">
               下拉项维护
               <div class="fullscreen">
                  <el-link style="margin-right: 5px" :underline="false" icon="el-icon-full-screen" @click="fullscreen = !fullscreen"></el-link>
                  <el-link :underline="false" icon="el-icon-close" @click="retailerClose"></el-link>
               </div>
            </template>
            <drop-pown :fullscreen="fullscreen" @select-item="selectItem"></drop-pown>
         </assembly-dialog>
      </div>
   </div>
</template>

<script>
import dropPown from './dropPown/index';
import searchOpenBtn from "@/components/assembly-search/search-open-btn";
import pagination from "@/components/assembly-pagination/index";
import AssemblyDialog from "components/assembly-dialog";

export default {
   name: "basicProcessing",
   components: {
      AssemblyDialog,
      dropPown,
      searchOpenBtn,
      pagination
   },
   watch: {
      //监听头部展开收起变化
      '$store.state.isHeader'() {
         this.setTableHeight()
      },
      //监听快速导航展开收起变化
      '$store.state.fastNav'() {
         this.setTableHeight()
      },
      //监听搜索区域变化改变列表
      searchShow() {
         this.setTableHeight()
      }
   },
   data() {
      return {
         currentPageSize: 10,
         dialogTitle: '新增',
         //搜索区域展开收起开关
         searchShow: false,
         //搜索区域展开收起图标
         searchOpenIcon: 'el-icon-arrow-down',
         //下拉列表开关
         dialogVisibleRetailer: false,
         //下拉列表开窗是否全屏
         fullscreen: false,
         //加载
         tableLoading: false,
         //表格高度
         tableHeight: 0,
         //表格数据
         tableData: [],
         //制造商数据
         manufacturerData: [],
         //品牌数据
         brandList: [],
         //上级参数列表
         paramParentData: [],
         //当前页面
         currentPage: 1,
         //总页数
         total: 0,
         //搜索表单
         searchForm: {
            paramMiName: '',
            paramMiType: '',
            paramMiCustomerId: '',
            paramMiSortId: '',
            paramMiStatus: '',
            paramMiParentId: '',
            paramMiBrandId: '',
            page: 1,
            pageSize: this.$store.state.initPageSize
         },
         //保存模态框开关
         dialogVisibleSave: false,
         //保存表单
         saveForm: {
            paramMiName: '',
            paramMiType: '',
            customer: '',
            brand: '',
            paramMiCustomerId: '',
            paramMiBrandId: '',
            paramMiSortId: '',
            paramMiStatus: 0,
            paramMiParentId: '',
            paramMiId: '',
         },
         //保存表单验证
         saveRules: {
            paramMiName: [
               { required: true, message: '请输入参数名称', trigger: 'blur' }
            ],
            paramMiType: [
               { required: true, message: '请选择参数类型', trigger: 'change' }
            ],
            paramMiCustomerId: [
               { required: true, message: '请选择所属制造商', trigger: 'change' }
            ],
            paramMiBrandId: [
               { required: true, message: '请选择品牌', trigger: 'change' }
            ],
            paramMiSortId: [
               { required: true, message: '请输入排序id', trigger: 'blur' }
            ],
            paramMiStatus: [
               { required: true, message: '请选择启用状态', trigger: 'change' }
            ],
            paramMiParentId: [
               { required: false, message: '请选择上级参数', trigger: 'change' }
            ]
         },
         //查看模态框开关
         dialogVisibleSee: false,
         seeForm: {
            paramMiName: '',
            paramMiType: '',
            paramMiCustomerId: '',
            paramMiSortId: '',
            paramMiStatus: '',
            paramMiParentId: ''
         },
         dialogVisibleSetOption: false,
         dynamicTags: [],
         inputVisible: false,
         inputValue: '',
         machiningId: '',
         isSave: 1, //编辑查询传1，新增传0
         isAdd: false
      }
   },
   mounted() {
      //初始化计算表格区域高度
      this.setTableHeight();
      this.searchSubmit();
      this.getManufacturerData();
      this.getParamParentData();
   },
   methods: {
      //判断权限是否存在
      hasPermission(item) {
         return this.$common.hasPermission(item);
      },
      /****表格高度计算 start********************************************************************************/
      //计算表格区域高度
      setTableHeight() {
         this.$nextTick(() => {
            this.tableHeight = this.$common.getHeight(this.$refs.search.clientHeight)
            window.onresize = () => {
               return (() => {
                  this.tableHeight = this.$common.getHeight(this.$refs.search.clientHeight)
               })();
            };
         })
      },
      /****表格高度计算 end********************************************************************************/
      /****搜素区域 start********************************************************************************/
      //搜索展开收起事件
      searchOpen(searchShow) {
         this.searchShow = searchShow
      },
      //搜索表单提交
      searchSubmit(flag) {
         if (flag) {
            this.currentPage = 1
            this.searchForm.page = 1
            this.searchForm.pageSize = this.currentPageSize
         }
         this.tableLoading = true,
            this.$server.getMachiningInfo(this.searchForm).then((res) => {
               this.tableLoading = false
               if (res.ok) {
                  this.tableData = res.data.records
                  this.total = res.data.total
               }
            }).catch(e => {
               this.tableLoading = false
               console.log(e)
            })
      },
      //搜索表单重置
      resetSubmit() {
         this.currentPage = 1
         this.$common.clearFormValue(this.searchForm)
         this.searchForm.page = 1,
            this.searchForm.pageSize = this.currentPageSize
         this.isAdd = false;
         this.searchSubmit()
      },
      //获取制造商列表
      getManufacturerData() {
         this.$server.getCustomerList({ sysCiType: 1 }).then((res) => {
            if (res.ok) {
               this.manufacturerData = res.data
            }
         })
      },
      //获取品牌列表
      changeCustomerType(row) {
         this.isSave = this.isAdd == true ? 0 : 1
         //this.showCode = true
         this.brandList = []
         // this.saveForm.goodsPiBrandId = ''
         // this.saveForm.goodsPiCode = ''
         this.$server.getBrandList({ 'goodsBiCustomerId': row, isSave: this.isSave }).then(res => {
            if (res.ok) {
               this.brandList = res.data
            }
         }).catch(e => {
            console.log(e)
         })
      },
      //获取上级参数搜索条件列表
      getParamParentData() {
         this.$server.getMachiningList().then((res) => {
            if (res.ok) {
               this.paramParentData = res.data
               let data = {
                  paramMiId: '0',
                  paramMiName: '暂无上级'
               }
               this.paramParentData.unshift(data)
            }
         })
      },
      /****搜素区域 end********************************************************************************/
      /****保存区域 start********************************************************************************/
      //保存表单提交
      saveOpen() {
         this.saveForm = {
            paramMiName: '',
            paramMiType: '',
            customer: '',
            brand: '',
            paramMiCustomerId: '',
            paramMiBrandId: '',
            paramMiSortId: '',
            paramMiStatus: 0,
            paramMiParentId: '',
            paramMiId: '',
         }
         this.dialogTitle = '新增'
         this.dialogVisibleSave = true;
         this.isAdd = true;
      },
      //模态框关闭前回调
      handleClose() {
         this.dialogVisibleSave = false
         this.clearForm('saveForm')
         this.brandList = []
      },
      //保存停启用状态
      saveMiStatus(row) {
         this.$server.saveMachiningInfo(row).then(res => {
            if (res.ok) {
               this.$message.success(res.msg)
               this.searchSubmit()
            } else {
               this.$message.error(res.msg)
            }
            this.dialogVisibleSave = false
            this.$common.clearFormValue(this.saveForm)
            this.clearForm('saveForm')
         }).catch(e => {
            console.log(e)
         })
      },
      //保存提交
      _resetPage() {
         this.currentPage = 1,
            this.searchForm.page = 1
      },
      saveSubmit(formName) {
         this.isAdd = false;
         this.$refs[formName].validate((valid) => {
            if (valid) {
               this.$server.saveMachiningInfo(this.saveForm).then(res => {
                  if (res.ok) {
                     this._resetPage()
                     this.$message.success(res.msg)
                     this.searchSubmit()
                  } else {
                     this.$message.error(res.msg)
                  }
                  this.dialogVisibleSave = false
                  this.$common.clearFormValue(this.saveForm)
                  this.clearForm('saveForm')
               }).catch(e => {
                  console.log(e)
               })
            } else {
               console.log('error submit!!');
               return false;
            }
         });
      },
      //清空表单
      clearForm(formName) {
         this.$refs[formName].resetFields();
         this.$common.clearFormValue(formName)
         this.clearForm(formName)
      },
      //编辑模态框开启
      editOpen(row) {
         this.isAdd = false;
         this.dialogTitle = '编辑'
         this.saveForm = JSON.parse(JSON.stringify(row))
         this.dialogVisibleSave = true
      },
      //选择制造商
      customerChange(e) {
         //console.log('选择所属客户',e)
         this.saveForm.paramMiCustomerId = e
         // this.searchForm.sysCiType = e

      },
      //选择上级参数
      parentParamChange(e) {
         this.saveForm.paramMiParentId = e
      },
      /****保存区域 end********************************************************************************/
      /****查看区域 start********************************************************************************/
      seeOpen(row) {
         this.seeForm = row
         this.dialogVisibleSee = true
      },
      seeClose() {
         this.dialogVisibleSee = false
      },
      /****查看区域 end********************************************************************************/
      /****查看区域 start********************************************************************************/
      setOptionOpen(e) {
         this.dialogVisibleRetailer = true
         console.log('id=========================', e)
         sessionStorage.setItem('machiningId', e)
      },
      setOptionClose() {
         console.log(this.dynamicTags)
         this.dialogVisibleSetOption = false
      },
      saveMachiningDroplist() {
         console.log(this.dynamicTags)
         let dropNames = this.dynamicTags.join(",")
         this.$server.saveMachiningDroplist({ machiningInfoId: this.machiningId, dropNames: dropNames }).then(res => {
            if (res.ok) {
               this.$message.success(res.msg)
               this.dialogVisibleSetOption = false

            } else {
               this.$message.error(res.msg)
               this.dialogVisibleSetOption = false
            }
         })
      },
      handleClose1(tag) {
         this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
      },

      showInput(tag) {
         this.inputVisible = true;
         this.$nextTick(_ => {
            this.$refs.saveTagInput.$refs.input.focus();
            console.log(typeof tag)
            if (typeof tag === 'string') {
               this.$refs.saveTagInput.$refs.input.value = tag;
            }
         });
      },

      handleInputConfirm() {
         let inputValue = this.inputValue;
         if (inputValue) {
            this.dynamicTags.push(inputValue);
         }
         this.inputVisible = false;
         this.inputValue = '';
      },
      /****查看区域 end********************************************************************************/
      /****删除区域 start********************************************************************************/
      delOpen(row) {
         this.$confirm('确认删除该条数据, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
         }).then(() => {
            this.$server.deleteMachining({ id: row }).then(res => {
               if (res.ok) {
                  this.$message.success(res.msg)
                  this.searchSubmit()
               } else {
                  this.$message.error(res.msg)
               }
            }).catch(e => {
               console.log(e)
            })
         }).catch(() => {
            this.$message({
               type: 'info',
               message: '已取消删除'
            });
         });
      },
      /****删除区域 end********************************************************************************/
      handleSizeChange(val) {
         this.currentPage = 1
         this.currentPageSize = val
         this.searchForm.page = 1
         this.searchForm.pageSize = val
         this.searchSubmit()
      },
      handleCurrentChange(val) {
         this.currentPage = val
         this.searchForm.page = val
         this.searchSubmit()
      },
      // 选择下拉框模态框关闭
      retailerClose() {
         this.dialogVisibleRetailer = false;
         let _this = this
         setTimeout(function () {
            _this.fullscreen = false
         }, 300)
      },
      // 选择下拉框选择
      selectItem(row) {
         console.log(row)
         this.dialogVisibleRetailer = false
         this.searchForm.retailer = row.shortName
      },
      //排序
      sortChange(row) {

         console.log('排序排序排序', JSON.stringify(row))
         if (row.prop == 'customer') {
            this.searchForm.orderByColumn = 'paramMiCustomerId'
         } else if (row.prop == 'paramType') {
            this.searchForm.orderByColumn = 'paramMiType'
         } else if (row.prop == 'parentName') {
            this.searchForm.orderByColumn = 'paramMiParentId'
         } else {
            this.searchForm.orderByColumn = row.prop
         }
         if (row.order == 'ascending') {
            this.searchForm.order = 'asc'
         } else if (row.order == 'descending') {
            this.searchForm.order = 'desc'

         } else {
            this.searchForm.order = ''
         }
         this.searchForm.page = 1;
         this.searchSubmit()
      },
      onSubmit() {
         console.log('submit!');
      }
   },
}
</script>

<style lang="scss">
.basicProcessing {
   .search {
      //搜索展开收起按钮位置设置
      .searchOpenBtn {
         float: right;
      }

      //表单每行底部外边距设置
      .el-form-item {
         margin-bottom: 10px;
      }

      .el-select {
         width: 200px;
      }

      .el-input {
         width: 200px;
      }

      //分割线外边距设置
      .el-divider--horizontal {
         margin: 0 0 10px 0;
      }
   }

   .el-dialog {
      .el-select {
         width: 100%;
      }

      .el-tag + .el-tag {
         //margin-left: 10px;
      }

      .button-new-tag {
         margin-left: 5px;
         height: 32px;
         line-height: 30px;
         padding-top: 0;
         padding-bottom: 0;
      }

      .input-new-tag {
         width: 90px;
         margin: 5px;
         vertical-align: bottom;
      }
      .el-dialog__header {
         .fullscreen {
            float: right;
            color: #909399;
            cursor: pointer;
            font-size: small;
            font-weight: 400;
            line-height: 18px;
         }
         .fullscreen:hover {
            //color: ;
         }
      }
   }
}
</style>

<style scoped>
::v-deep .el-dialog {
   display: flex;
   flex-direction: column;
   margin: 0 !important;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   max-height: calc(100% - 30px);
   max-width: calc(100% - 30px);
}
</style>
