<template>
  <div class="dropPown">
    <div ref="search" class="search">
      <el-card class="box-card" shadow="never" :body-style="{padding:'10px'}">
        <el-form :inline="true" :model="searchForm" label-width="80px">
          <el-form-item label="名称">
            <el-input v-model="searchForm.paramPdDroplistName" :size="$store.state.size" placeholder="名称" clearable
                      @keyup.enter.native="searchSubmit(true)"></el-input>
          </el-form-item>
          <el-form-item label="ID">
            <el-input v-model="searchForm.paramPdDroplistId" :size="$store.state.size" placeholder="ID" clearable></el-input>
          </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <el-button type="primary" :size="$store.state.size" @click="searchSubmit" icon="el-icon-search"
                   @keyup.enter.native="searchSubmit(true)">查询</el-button>
        <el-button type="default" plain :size="$store.state.size" @click="resetSubmit" icon="el-icon-delete">清空</el-button>
        <el-button type="primary" :size="$store.state.size" @click="saveOpen" icon="el-icon-search">新增</el-button>

      </el-card>
    </div>
    <el-table
        :data="tableData"
        :height="tableHeight - 150"
        border
        v-loading="tableLoading"
        :size="this.$common.tableLineHeight()"
        :header-cell-style="{background:'#f5f7fa',color:'#515a6e', fontWeight:'900'}"
        style="width: 100%; margin-top: 10px">
      <el-table-column
          prop="paramPdDroplistName"
          align="center"
          label="名称">
      </el-table-column>
      <el-table-column
          prop="paramPdDroplistId"
          align="center"
          label="ID">
      </el-table-column>
      <el-table-column
          align="center"
          label="操作"
          width="260">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="delOpen(scope.row.paramPdDroplistId)" icon="el-icon-delete">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination
        :current-page="currentPage"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange">
    </pagination>

    <!--    保存模态框-->
    <assembly-dialog
        title="保存"
        :visible.sync="dialogVisibleSave"
        width="30%"
        @before-close="handleClose"
        append-to-body>
      <el-form :model="saveForm" :rules="saveRules" ref="saveForm" label-width="80px">
        <el-form-item label="名称" prop="paramPdDroplistName">
          <el-input v-model="saveForm.paramPdDroplistName" :size="$store.state.size" placeholder="名称" clearable></el-input>
        </el-form-item>
        <el-form-item label="ID" prop="paramPdDroplistId">
          <el-input v-model="saveForm.paramPdDroplistId" :size="$store.state.size" placeholder="ID" clearable></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button :size="$store.state.size" @click="clearForm('saveForm')">清 空</el-button>
        <el-button :size="$store.state.size" type="primary" @click="saveSubmit('saveForm')">确 定</el-button>
      </span>
    </assembly-dialog>
  </div>
</template>

<script>
import pagination from "@/components/assembly-pagination/index";
import AssemblyDialog from "components/assembly-dialog";

export default {
  name: "dropPown",
  props:['fullscreen'],
  components:{
    AssemblyDialog,
    pagination
  },
  watch: {
    //监听搜索区域变化改变列表
    searchShow() {
      this.setTableHeight()
    },
    //监听窗口变化fullscreen
    fullscreen(){
      this.setTableHeight()
    }
  },
  data() {
    return {
      //搜索区域展开收起开关
      searchShow: false,
      //搜索区域展开收起图标
      searchOpenIcon: 'el-icon-arrow-down',
      //保存模态框显示
      dialogVisibleSave:false,
      //表格高度
      tableHeight: 0,
      //表格数据
      tableData: [
        {
          fullName: '河西XXX零售商1',
          shortName: '零售商1',
        }, {
          fullName: '河西XXX零售商2',
          shortName: '零售商2',
        }],
      //当前页面
      currentPage: 1,
      //总页数
      total:0,
      //搜索表单
      searchForm: {
        page: 1,
        pageSize: this.$store.state.initPageSize,
        paramPdMachiningId: '',
        order: '',
        orderByColumn:'',
        paramPdDroplistId:'',
        paramPdDroplistName:''
      },
      //加载
      tableLoading: false,
      saveForm:{
        paramPdMachiningId:'',
        paramPdDroplistId:'',
        paramPdDroplistName:'',
      },
      //保存表单验证
      saveRules: {
        paramPdDroplistId: [
          {required: true, message: '请输入ID', trigger: 'blur'}
        ],
        paramPdDroplistName: [
          {required: true, message: '请输入名称', trigger: 'change'}
        ]
      },
    }
  },
  mounted() {
    //初始化计算表格区域高度
    this.setTableHeight();
  //  初始化表单数据
    this.searchSubmit();
  },
  methods: {
    /****表格高度计算 start********************************************************************************/
    //计算表格区域高度
    setTableHeight() {
      this.$nextTick(() => {
        this.tableHeight = this.$common.getHeight(this.$refs.search.clientHeight-30)
        window.onresize = () => {
          return (() => {
            this.tableHeight = this.$common.getHeight(this.$refs.search.clientHeight-30)
          })();
        };
      })
    },
    /****表格高度计算 end********************************************************************************/
    /****搜素区域 start********************************************************************************/
    //搜索展开收起事件
    searchOpen(searchShow) {
      this.searchShow = searchShow
    },
    //搜索表单提交
    searchSubmit(flag) {
      if(flag){
        this.searchForm.page = 1
      }
     let machiningId = sessionStorage.getItem('machiningId')
      this.tableLoading = true,
      this.searchForm.paramPdMachiningId = machiningId
      this.$server.getMachiningGetByPage( this.searchForm).then(res => {
        this.tableLoading = false
        if (res.ok) {
          this.tableData = res.data.records
          this.total = res.data.total
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    //搜索表单重置
    resetSubmit() {
      this.$common.clearFormValue(this.searchForm)
      this.searchForm.page= 1
      this.searchForm.pageSize= this.$store.state.initPageSize,
      this.searchSubmit()
    },
    /****搜素区域 end********************************************************************************/
    /****删除区域 start********************************************************************************/
    delOpen(row) {
      this.$confirm('确认删除该条数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$server.deleteDroplist({paramPdDroplistId: row,paramPdMachiningId:sessionStorage.getItem('machiningId')}).then(res =>{
          if (res.ok){
            this.$message.success(res.msg)
            this.searchSubmit()
          }else {
            this.$message.error(res.msg)
          }
        }).catch(e =>{
          console.log(e)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    /****删除区域 end********************************************************************************/
    /****保存区域 start********************************************************************************/
    //保存表单提交打开
    saveOpen() {
      this.dialogVisibleSave = true

    },
    //模态框关闭前回调
    handleClose() {
        this.dialogVisibleSave = false
        this.clearForm('saveForm')
    },
    //保存提交
    saveSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let machiningId = sessionStorage.getItem('machiningId')
          this.saveForm.paramPdMachiningId = machiningId
          this.$server.saveMachiningDroplist( this.saveForm).then(res =>{
            if (res.ok){
              this.$message.success(res.msg)
              this.searchSubmit()
            }else {
              this.$message.error(res.msg)
            }
            this.dialogVisibleSave = false
            this.$common.clearFormValue(this.saveForm)
          }).catch(e =>{
            console.log(e)
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //清空表单
    clearForm(formName) {
      this.$refs[formName].resetFields();
      this.$common.clearFormValue(this.saveForm)
      this.searchSubmit()
    },
    //编辑模态框开启
    editOpen(row){
      this.saveForm = JSON.parse(JSON.stringify(row))
      console.log('编辑模态框开启',JSON.stringify(this.saveForm));

      this.dialogVisibleSave = true
    },
    /****保存区域 end********************************************************************************/
    /****表选择 start********************************************************************************/
    selectItem(row){
      return this.$emit("select-item", row)
    },
    /****表选择 end********************************************************************************/
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    onSubmit() {
      console.log('submit!');
    }
  },
}
</script>

<style lang="scss">
.dropPown {
  .search {
    //搜索展开收起按钮位置设置
    .searchOpenBtn {
      float: right;
    }

    //表单每行底部外边距设置
    .el-form-item {
      margin-bottom: 10px;
    }

    .el-select {
      width: 200px;
    }

    .el-input {
      width: 200px;
    }

    //分割线外边距设置
    .el-divider--horizontal {
      margin: 0 0 10px 0;
    }
  }

  .el-dialog {
    .el-select {
      width: 100%;
    }
  }
}
</style>
